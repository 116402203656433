import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { CurrencyConfigList } from './currencyconfigviewlist.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyconfigviewService {
  url = environment.Url;
  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }
  constructor(private http: HttpClient,
    private cookie: CookieService) { }
  // app slider List View
  CurrencyConfigListView(page) {
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });
    return this.http.get(`${this.url}/currencyPageView/${page}`, {
      headers: httpheaders,
      observe: 'response'
    });
  }



}

