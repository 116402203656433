import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { BookingsService } from '../../bookings/bookings.service';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {


  constructor(private router: Router, private service: BookingsService, private toast: ToastrService) { }

  public setintervalid = setInterval(() => {
    this.AutogetBookingsNotification();
  }, 5000);

  AutogetBookingsNotification() {
    this.service.latestBooking().subscribe((res: any) => {
      if (res.body['error']) {
        environment.swalalert('nodata', res.body['msg']);
      } else {
        if (res.body['data'][0].data.length > 0) {
          var count = res.body['data'][0].Count;
          if (!localStorage.getItem("bookingCount")) {
            localStorage.setItem("bookingCount", count)
          }
          var bookingData = res.body['data'][0].data;
          if (count > localStorage.getItem("bookingCount")) {
            localStorage.setItem("bookingCount", count)
            console.log("bookingData", bookingData)
            this.showToaster(bookingData[0].Id)

          }
        }
      }
    },
      (err) => {
        console.log(err);
      })
  }

  ngOnInit() {
    // $(document).ready(function () {
    //   $(".sidebar li .dasboard").addClass('clickactive');
    //   $(".sidebar li a").click(function () {
    //     $(".sidebar li a").removeClass('clickactive');
    //     $(this).addClass('clickactive');
    //   });
    // });

    $(".sidebar li a").click(function() {
      var targetDiv = this.getElementsByClassName("fa")[1].className
      var tar = this.getElementsByClassName("fa")[1]
      // console.log(targetDiv.className)
      if (targetDiv == 'fa fa-chevron-right pull-right') {
        tar.className = "fa fa-chevron-down pull-right"
      } else {
        tar.className = "fa fa-chevron-right pull-right"
      }
    });

  }

  showToaster(Id) {
    const audio = new Audio();
    audio.src = './assets/audio/alert_tone.mp3';
    audio.load();
    audio.play();
    this.toast.warning('Click Here to Go To Booking!', 'New Booking Request')
      .onTap
      .subscribe(() => this.toasterClickedHandler(Id));
  }

  toasterClickedHandler(Id) {
    console.log("id", Id)
    // this.router.navigate(['bookingsView', { queryParams: { id: Id } }])
    this.router.navigate(['bookingsView', Id])
  }

}
