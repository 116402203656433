import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule, NgbPaginationModule, NgbAlertModule, NgbDatepicker, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ScrollEventModule } from 'ngx-scroll-event';
import { CKEditorModule } from 'ngx-ckeditor';
import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from "agm-overlays"
import { ChartsModule, ThemeService } from 'ng2-charts';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmDirectionModule } from 'agm-direction'
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterComponent } from './register/register.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ControlMessagesComponent } from './login/control-messages.component'
import { CurrencyconfigviewComponent } from './layout/currencyconfig/currencyconfigview.component';
import { CurrencyconfigeditComponent } from './layout/currencyconfigedit/currencyconfigedit.component';


import { LoginComponent } from './login/login.component';
import { LayoutComponent } from "./layout/layout.component";
import { NavbarComponent } from "./layout/navbar/navbar.component";
import { SidebarComponent } from "./layout/sidebar/sidebar/sidebar.component";
import { EncryService } from './encry.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';



@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    NotFoundComponent,
    LoginComponent,
    ControlMessagesComponent,
    LayoutComponent,
    NavbarComponent,
    SidebarComponent,
    CurrencyconfigviewComponent,
    CurrencyconfigeditComponent
  ],
  imports: [
    NgbPaginationModule,
    NgxMaterialTimepickerModule,
    BrowserAnimationsModule,
    NgbModule,
    NgbAlertModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgProgressModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ScrollEventModule,
    CKEditorModule,
    DatePickerModule,
    AgmOverlays,
    AgmJsMarkerClustererModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapApiKey,
      language: "en",
      libraries: ["geometry", "places"],
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      disableTimeOut: true,
      closeButton: true,
      positionClass: 'toast-top-right',
      progressBar: true,
      tapToDismiss: true,
      autoDismiss: false
    }),
    AgmDirectionModule,
    NgProgressModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    ChartsModule
  ],
  providers: [
    EncryService,
    CookieService,
    ThemeService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
