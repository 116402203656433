import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { from } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppComponent } from 'src/app/app.component';
import { CurrencyConfigViewModel } from './currencyconfigview.model';
import { CurrencyConfigList } from './currencyconfigviewlist.model';
import { CurrencyconfigviewService } from './currencyconfigview.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-currencyconfigview',
  templateUrl: './currencyconfigview.component.html',
  styleUrls: ['./currencyconfigview.component.css']
})
export class CurrencyconfigviewComponent implements OnInit {

  result: any;
  page: number = 1;
  pages: any;
  currencyconfigview: CurrencyConfigViewModel;
  CurrencyConfigList: CurrencyConfigList[];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private service: CurrencyconfigviewService,
    private appspinner: AppComponent,
    private cookie: CookieService
  ) { }
  ngOnInit() {
    this.appspinner.spinnerAlert('show');
    this.service.CurrencyConfigListView(this.page).subscribe((res) => {
      if (res.body['error']) {
        environment.swalalert('nodata', res.body['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
        this.currencyconfigview = new CurrencyConfigViewModel(res.body['error'], res.body['msg'], res.body['data']);
        if (res.body['data'].length > 0 && this.currencyconfigview['data'][0].data.length > 0) {
          this.pages = this.currencyconfigview['data'][0].Count;
          this.result = this.currencyconfigview['data'][0].data;
          this.appspinner.spinnerAlert('hide');
          console.log(this.result);

        } else {
          environment.swalalert('nodata', 'No Data Available ');
          this.appspinner.spinnerAlert('hide');
        }
      }
    },
      (err) => {
        console.log(err);
        if (err['status'] === 401) {
          environment.swalalert('nodata', err['msg']);
          // this.cookie.delete('Token');
          localStorage.removeItem('Token');
          this.router.navigate(['login']);
        }
      })
  }
  getcurrencyconfigview(page) {

    this.page = page;
    this.appspinner.spinnerAlert('show');
    // this.cookie.set('CurrencyConfigList', page);
    localStorage.setItem('CurrencyConfigList', page);
    this.service.CurrencyConfigListView(page).subscribe((res) => {

      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
        this.currencyconfigview = new CurrencyConfigViewModel(res.body['error'], res.body['msg'], res.body['data']);
        if (res.body['data'].length > 0 && this.currencyconfigview['data'][0].data.length > 0) {
          this.pages = this.currencyconfigview['data'][0].Count;
          this.result = this.currencyconfigview['data'][0].data;
          this.appspinner.spinnerAlert('hide');



        } else {
          environment.swalalert('nodata', 'No Data Available ');
          this.appspinner.spinnerAlert('hide');
        }
      }
    },
      (err) => {
        console.log(err);
      })
  }


}


