export class CurrencyConfigEditModel {
  public Id: Number;
  public Country: String;
  public CurrencySymbol: String;

  constructor(Id: Number,
    Country: String,
    CurrencySymbol: String,

  ) {
    this.Id = Id;
    this.Country = Country;
    this.CurrencySymbol = CurrencySymbol;

  }
}
