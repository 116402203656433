
import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { GuardGuard } from "./guard.guard";
import { NotFoundComponent } from "./not-found/not-found.component";
import { CurrencyconfigviewComponent } from './layout/currencyconfig/currencyconfigview.component';
import { CurrencyconfigeditComponent } from './layout/currencyconfigedit/currencyconfigedit.component';

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "signup", component: RegisterComponent },
  {
    path: "dashboard",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "dashboard",
        loadChildren: "./layout/dashboard/dashboard.module#DashboardModule",
        canActivate: [GuardGuard],
      },
      {
        path: "Users",
        loadChildren:
          "./layout/user-list/userlistview.module#UserlistviewModule",
        canActivate: [GuardGuard],
      },
      {
        path: "Providers",
        loadChildren:
          "./layout/provider-list/providerlist.module#ProviderlistModule",
        canActivate: [GuardGuard],
      },
      {
        path: "countryView",
        loadChildren: "./layout/country/country.module#CountryModule",
        canActivate: [GuardGuard],
      },
      {
        path: "stateView",
        loadChildren: "./layout/state/state.module#StateModule",
        canActivate: [GuardGuard],
      },
      {
        path: "godsView",
        loadChildren: "./layout/godsview/godsview.module#GodsviewModule",
        canActivate: [GuardGuard],
      },
      {
        path: "bookings",
        loadChildren: "./layout/bookings/bookings.module#BookingsModule",
        canActivate: [GuardGuard],
      },
      {
        path: "bookingsView/:id",
        loadChildren:
          "./layout/bookingsview/bookingsview.module#BookingsviewModule",
        canActivate: [GuardGuard],
      },
      {
        path: "pushNotificationView",
        loadChildren:
          "./layout/pushnotification/pushnotification.module#PushnotificationModule",
        canActivate: [GuardGuard],
      },
      {
        path: "manualBook",
        loadChildren: "./layout/manualbook/manualbook.module#ManualbookModule",
        canActivate: [GuardGuard],
      },
      {
        path: "cityView",
        loadChildren: "./layout/city/city.module#CityModule",
        canActivate: [GuardGuard],
      },
      {
        path: "doctypeView",
        loadChildren: "./layout/doctype/doctype.module#DoctypeModule",
        canActivate: [GuardGuard],
      },
      {
        path: "ridetypeView",
        loadChildren: "./layout/ride-type/ridetype.module#RidetypeModule",
        canActivate: [GuardGuard],
      },
      {
        path: "rideVehicletypeView",
        loadChildren:
          "./layout/ride-type-vehicle/ridetypevehicle.module#RidetypevehicleModule",
        canActivate: [GuardGuard],
      },
      {
        path: "appConfig",
        loadChildren: "./layout/appconfig/appconfig.module#AppconfigModule",
        canActivate: [GuardGuard],
      },
      {
        path: "appConfigView",
        loadChildren:
          "./layout/appconfigview/appconfigview.module#AppconfigviewModule",
        canActivate: [GuardGuard],
      },
      {
        path: "appConfig/:id",
        loadChildren: "./layout/appconfig/appconfig.module#AppconfigModule",
        canActivate: [GuardGuard],
      },
      {
        path: "vehicleBrandView",
        loadChildren:
          "./layout/vehicle-brand/vehiclebrand.module#VehiclebrandModule",
        canActivate: [GuardGuard],
      },
      {
        path: "vehicleModelView",
        loadChildren:
          "./layout/vehicle-model/vehiclemodel.module#VehiclemodelModule",
        canActivate: [GuardGuard],
      },
      {
        path: "cancellationPolicyView",
        loadChildren:
          "./layout/cancellation-policy/cancellationpolicy.module#CancellationpolicyModule",
        canActivate: [GuardGuard],
      },
      {
        path: "staticPagesView",
        loadChildren:
          "./layout/static-pages/staticpages.module#StaticpagesModule",
        canActivate: [GuardGuard],
      },
      {
        path: "emailTemplateView",
        loadChildren:
          "./layout/email-template/emailtemplate.module#EmailtemplateModule",
        canActivate: [GuardGuard],
      },
      {
        path: "appSliderView",
        loadChildren: "./layout/appslider/appslider.module#AppsliderModule",
        canActivate: [GuardGuard],
      },
      {
        path: "providerVehicleView/:id",
        loadChildren:
          "./layout/providervehicleview/providervehicleview.module#ProvidervehicleviewModule",
        canActivate: [GuardGuard],
      },
      {
        path: "providerListView/:id",
        loadChildren:
          "./layout/provider-listview/providerlistview.module#ProviderlistviewModule",
        canActivate: [GuardGuard],
      },
      {
        path: "billing",
        loadChildren:
          "./layout/billingpanel/billingpanel.module#BillingpanelModule",
        canActivate: [GuardGuard],
      },
      {
        path: "companyprofile",
        loadChildren:
          "./layout/companyprofile/companyprofile.module#CompanyprofileModule",
        canActivate: [GuardGuard],
      },
      {
        path: "rental",
        loadChildren:
          "./layout/rentaloutsation/rentaloutstation.module#RentaloutstationModule",
        canActivate: [GuardGuard],
      },
      {
        path: "ridelaterbook",
        loadChildren:
          "./layout/ridelaterbooking/ridelaterbooking.module#RidelaterbookingModule",
        canActivate: [GuardGuard],
      },
      {
        path: "bookingheatmap",
        loadChildren:
          "./layout/bookingheatmap/bookingheatmap.module#BookingheatmapModule",
        canActivate: [GuardGuard],
      },
      {
        path: "reviewmanagement",
        loadChildren:
          "./layout/reviewmanagement/reviewmanagement.module#ReviewmanagementModule",
        canActivate: [GuardGuard],
      },
      {
        path: "bannerads",
        loadChildren: "./layout/bannerads/bannerads.module#BanneradsModule",
        canActivate: [GuardGuard],
      },
      {
        path: "providercancellation",
        loadChildren:
          "./layout/providercancellation/providercancellation.module#ProvidercancellationModule",
        canActivate: [GuardGuard],
      },
      {
        path: "servicelocation",
        loadChildren:
          "./layout/servicelocation/servicelocation.module#ServicelocationModule",
        canActivate: [GuardGuard],
      },
      {
        path: "edithomepage",
        loadChildren:
          "./layout/edithomepage/edithomepage.module#EdithomepageModule",
        canActivate: [GuardGuard],
      },
      {
        path: "latestnewsupdate",
        loadChildren:
          "./layout/latestnewsupdate/latestnewsupdate.module#LatestnewsupdateModule",
        canActivate: [GuardGuard],
      },
      {
        path: "newslettersubs",
        loadChildren:
          "./layout/newslettersubs/newslettersubs.module#NewslettersubsModule",
        canActivate: [GuardGuard],
      },
      {
        path: "faqs",
        loadChildren: "./layout/faqs/faqs.module#FaqsModule",
        canActivate: [GuardGuard],
      },
      {
        path: "dbbackup",
        loadChildren: "./layout/dbbackup/dbbackup.module#DbbackupModule",
        canActivate: [GuardGuard],
      },
      {
        path: "systemdia",
        loadChildren: "./layout/systemdia/systemedia.module#SystemediaModule",
        canActivate: [GuardGuard],
      },
      {
        path: "locationbased",
        loadChildren:
          "./layout/locationbased/locationbased.module#LocationbasedModule",
        canActivate: [GuardGuard],
      },
      {
        path: "surgepricemanage",
        loadChildren:
          "./layout/surgepricemanage/surgepricemanage.module#SurgepricemanageModule",
        canActivate: [GuardGuard],
      },
      {
        path: "reports",
        loadChildren: "./layout/reports/reports.module#ReportsModule",
        canActivate: [GuardGuard],
      },
      {
        path: "promoCodes",
        loadChildren: "./layout/promocode/promocodes.module#PromocodesModule",
        canActivate: [GuardGuard],
      },
      {
        path: "peekCharges",
        loadChildren:
          "./layout/peekcharges/peekcharges.module#PeekchargesModule",
        canActivate: [GuardGuard],
      },
      {
        path: "withDrawlRequest",
        loadChildren:
          "./layout/withdrawlrequest/withdrawalrequest.module#WithdrawalrequestModule",
        canActivate: [GuardGuard],
      },
      { path: 'currencyConfigView', component: CurrencyconfigviewComponent, canActivate: [GuardGuard] },
      { path: 'currencyConfigEdit/:id', component: CurrencyconfigeditComponent, canActivate: [GuardGuard] },
    ],
    canActivate: [GuardGuard],
  },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{preloadingStrategy:PreloadAllModules})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
